import { Dialog, LinearProgress, linearProgressClasses, styled, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "../../../../assets/icons/editIcon";
import Button from "../../../../atoms/Button";
import InfoCard from "../../../../components/infoCard/InfoCard";
import { getProfileInfo } from "../../../../components/profileMenu/selectors";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import { ROLE_PERMISSION_STATUS } from "../../../../constants/constants";
import { ToastType } from "../../../../molecules/toaster/enum";
import { addToast } from "../../../../molecules/toaster/toastSlice";
import { PermissionListEnum } from "../../../../organisms/auth/enum";
import { ownerNameUpdate, resetOwnerName } from "../../../../organisms/propertySearchFilter/PropertyService";
import { setPropertyDetails } from "../../property.reducer";
import { formartNumberAsLocalString, formatCurrency } from "../../../../utils/utils";
import { getPropertyDetails } from "../../property.selector";
import styles from "./PropertyDetailTab.module.scss";

const SectionItem = ({ title, value }: { title: string; value: string }) => {
  if (typeof value === "boolean") {
    value = value ? "Yes" : "No";
  }

  if (typeof value === "number") {
    value = formartNumberAsLocalString(value);
  }

  value = Boolean(value) ? value : "-";
  return (
    <div className={`${styles.row__col}`}>
      <InfoCard title={title} description={value} />
    </div>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    background: theme.palette.mode === "light" ? "#005DA4" : "#005DA4",
  },
}));
const PropertyDetailTab = ({ selectedPropertyId,type }: any) => {
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
 
    const {
      owner,
      propertyInformation,
      lot,
      ownerInfo,
      currentMortgages,
      lastSalePrice,
      ownerOccupied,
      absenteeOwner,
      tax,
      mlsActive,
      mlsPending,
      mlsHistory,
      demographics,
      valuation,
      landInformation,legal,generalInfo
    } = propertyDetails;

  const sections = {
    "Ownership Information": [
      {
        title: "Owner 1 Name",
        value: ownerInfo?.ownerNames?.[0]?.fullName,
      },
      {
        title: "Owner 2 Name",
        value:ownerInfo?.ownerNames?.[1]?.fullName,
      },
      {
        title: "Length of Ownership",
        value:ownerInfo?.residencePeriodInYear && ownerInfo?.residencePeriodInYear!=0 ? 
        (ownerInfo?.residencePeriodInYear)>=1?Math.round(ownerInfo?.residencePeriodInYear) + ` ${Math.round(ownerInfo?.residencePeriodInYear/12)>1?'Years':'Year'}`  :
        ownerInfo?.residencePeriodInYear + ` ${ownerInfo?.residencePeriodInYear>1?'Months':'Month'}` 
        : "",
      },
      {
        title: "Mailing Address",
        value: ownerInfo?.mailingAddress,
      },
    ],
    "Property Information": [
      {
        title: "Bedrooms",
        value: propertyInformation?.bedroomCount ? propertyInformation?.bedroomCount : "",
      },
      {
        title: "Full Bathrooms",
        value: propertyInformation?.bathroomCount ? propertyInformation?.bathroomCount : "",
      },
      {
        title: "Partial Bathrooms",
        value: propertyInformation?.partialBathroomCount ? propertyInformation?.partialBathroomCount : "",
      },
      {
        title: "Living Area",
        value: propertyInformation?.livingAreaSquareFeet
          ? formartNumberAsLocalString(propertyInformation?.livingAreaSquareFeet) +
            " SqFt."
          : "",
      },
      {
        title: "Stories",
        value: propertyInformation?.storyCount ? propertyInformation?.storyCount : "",
      },
      {
        title: "Property Use",
        value: generalInfo?.propertyTypeCategory ,
      },
      {
        title: "Residential Units",
        value: propertyInformation?.residentialUnitCount !=0 ? propertyInformation?.residentialUnitCount : "",
      },
      {
        title: "Basement",
        value: propertyInformation?.basementType,
      },
      {
        title: "Basement Size",
        value: propertyInformation?.basementSquareFeet
          ? formartNumberAsLocalString(propertyInformation?.basementSquareFeet) +
            " SqFt."
          : "",
      },
      {
        title: "Parking Spaces",
        value: propertyInformation?.garageParkingSpaceCount ? propertyInformation?.garageParkingSpaceCount : "",
      },
      {
        title: "Fireplaces",
        value: propertyInformation?.fireplaceCount ? propertyInformation?.fireplaceCount : "",
      },
      {
        title: "Air Conditioning",
        value: propertyInformation?.airConditioningSource,
      },
      {
        title: "Heating",
        value: propertyInformation?.heatSource,
      },
      {
        title: "Heating Fuel",
        value: propertyInformation?.heatingFuelType,
      },
      {
        title: "Water Source",
        value: propertyInformation?.waterService,
        

      },
      {
        title: "Garage",
        value: propertyInformation?.garage,
      },
      {
        title: "Garage Size",
        value: propertyInformation?.garageSquareFeet && propertyInformation?.garageSquareFeet!=0
          ? formartNumberAsLocalString(propertyInformation?.garageSquareFeet) +
            " SqFt."
          :
           "-",
      },
      {
        title: "Carport",
        value: "-",
      },
      {
        title: "Pool",
        value: propertyInformation?.pool,
      },
    ],
    "Land/Location Information": [
      {
        title: "Lot Size",
        value: lot?.lotSizeAcres && lot?.lotSizeAcres !=0
          ? formartNumberAsLocalString(lot?.lotSizeAcres) + " acres"
          : "",
      },
      {
        title: "Lot Area",
        value: lot?.lotSizeSquareFeet && lot?.lotSizeSquareFeet !=0
          ? formartNumberAsLocalString(lot?.lotSizeSquareFeet) + " sqft"
          : "",
      },
      {
        title: "Property Class",
        value: propertyInformation?.buildingClass ? propertyInformation?.buildingClass : "-",
      },
      {
        title: "APN",
        value: landInformation?.apn,
      },
      {
        title: "Zoning",
        value: lot?.zoningCode ? lot?.zoningCode :"-",
      },
      {
        title: "Census Tract",
        value: landInformation?.censusTract,
      },
      {
        title: "Block",
        value: landInformation?.block,
      },
      {
        title: "Lot Number",
        value: landInformation?.lotNumber ? landInformation?.lotNumber : "",
      },
      {
        title: "Neighborhood Name",
        value: legal?.subdivisionName ? legal?.subdivisionName :"-",
      },
      
      {
        title: "Legal Description",
        value: legal?.legalDescription,
      },
    ],
  };
  const dispatch = useDispatch();
  const [editNameModal, setEditNameModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [ownerFullName, setOwnerFullName] = useState("");
  const nameRef = useRef("");
  const profileInfo = useSelector((state) => getProfileInfo(state));
  
  const handleEditName = () => {
    setEditNameModal(!editNameModal);
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleSaveName = async () => {
    if (nameRef.current === "") {
      handleShowToast("Owner name is required.", ToastType.error);
    } else if (nameRef.current === ownerFullName) {
      handleEditName();
    } else {
      setLoading(true);
      try {
       const res= await ownerNameUpdate(selectedPropertyId, {
          fullName: nameRef.current,
        },type);
        setOwnerFullName(nameRef.current);
      const updatedPropertyDetails = {
        ...propertyDetails,
        owner: propertyDetails.owner?.find((owner: any) => owner.createdBy)
          ? propertyDetails.owner.map((owner: any) =>
              owner.createdBy ? res.data : owner
            )
          : [...(propertyDetails.owner || []), res?.data]
      };
       dispatch(setPropertyDetails( updatedPropertyDetails));
        handleShowToast(
          "Owner name is updated successfully!",
          ToastType.success
        );
        handleEditName();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleResetName = async () => {
    try {
      setResetLoading(true);
      await resetOwnerName(selectedPropertyId,type);
      setOwnerFullName(propertyDetails?.ownerInfo?.ownerNames?.[0]?.fullName);
      handleShowToast("Owner name reset successfully!", ToastType.success);
      const updatedPropertyDetails = {
        ...propertyDetails,
        owner: propertyDetails.owner?.find((owner: any) => owner.createdBy)
          ? propertyDetails.owner.filter((owner: any) => !owner.createdBy)
          : propertyDetails.owner
      };
      dispatch(setPropertyDetails( updatedPropertyDetails));
      handleEditName();
      setResetLoading(false);
    } catch (error: any) {
      console.log(error);
      handleShowToast(error?.response?.statusText, ToastType.error);
      setResetLoading(false);
    }
  };

  const BelowMap = () => {
    return (
      <div className={` ${styles.propertyDetailsTab}`}>
        <div className="wrapper bottom-zero">
          <div
            className={`dflex justifySpaceBetween ${styles.propertyDetails}`}
          >
            <div className={`${styles.propertyBox}`}>
              <h3>Owner Name</h3>
              <p className={`${styles.propertyBox__edit}`}>
                {ownerFullName}
                {profileInfo?.userRoleName ===
                PermissionListEnum.ORGANISATION_VIEWER ? (
                  <Tooltip title={ROLE_PERMISSION_STATUS.NoAccess}>
                    <span role="disable">
                      <EditIcon />
                    </span>
                  </Tooltip>
                ) : (
                  <span role="link" onClick={handleEditName}>
                    <EditIcon />
                  </span>
                )}
              </p>
              <h3>Occupancy</h3>
              <p>
                {ownerOccupied
                  ? "Owner Occupied"
                  : absenteeOwner
                  ? "Abstentee Owner"
                  : "Vacant"}
              </p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>
                Mortgages <span>{currentMortgages?.length||0}</span>
              </h3>
              <p>
                {currentMortgages?.length !== 0
                  ? formatCurrency(
                      currentMortgages?.[0]?.currentEstimatedBalance
                    ) +
                    " | " +
                    formatCurrency(
                      currentMortgages?.[0]?.estimatedPaymentAmount
                    ) +
                    "/mo"
                  : "-"}
              </p>

              <h3>Taxes</h3>
              <p>{formatCurrency(tax?.taxAmount / 12)}/mo</p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>
                Equity <small>(est.)</small>
              </h3>
              {propertyDetails?.valuation && valuation ? (
                <>
                  <p>
                    {formatCurrency(valuation?.equityCurrentEstimatedBalance)} |{" "}
                    {valuation?.equityPercent}% |{" "}
                    {valuation?.equityPercent < 35
                      ? "Low"
                      : valuation?.equityPercent >= 35 &&
                        valuation?.equityPercent <= 65
                      ? "Average"
                      : "High"}
                  </p>
                  <div className={`${styles.progress}`}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={valuation?.equityPercent}
                    />
                  </div>
                </>
              ) : (
                "-"
              )}
              <h3>Est. Value</h3>
              <p>{formatCurrency(valuation?.estimatedValue)}</p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>Last Sale</h3>
              <p>{formatCurrency(lastSalePrice)}</p>
              <h3>MLS</h3>
              <p>
                {mlsActive || mlsPending
                  ? formatCurrency(mlsHistory[0]?.price)
                  : "Inactive"}
              </p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>
                FMR <small>(HUD)</small>
              </h3>
              {/* <p>{formatCurrency(demographics.medianIncome)}/mo</p> */}
              <p>
                {propertyInformation?.bedroomCount ? (
                  <>
                    {propertyInformation?.bedroomCount === 0 &&
                      `${formatCurrency(demographics?.fmrEfficiency)}/mo`}
                    {propertyInformation?.bedroomCount === 1 &&
                      `${formatCurrency(demographics?.fmrOneBedroom)}/mo`}
                    {propertyInformation?.bedroomCount === 2 &&
                      `${formatCurrency(demographics?.fmrTwoBedroom)}/mo`}
                    {propertyInformation?.bedroomCount === 3 &&
                      `${formatCurrency(demographics?.fmrThreeBedroom)}/mo`}
                    {propertyInformation?.bedroomCount >= 4 &&
                      `${formatCurrency(demographics?.fmrFourBedroom)}/mo`}
                  </>
                ) : (
                  "-"
                )}
              </p>
              <h3>
                Rent <small>(est.)</small>
              </h3>
              <p>
                {demographics?.suggestedRent
                  ? `${formatCurrency(demographics?.suggestedRent)}/mo`
                  : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const selectedOwner = owner?.find((owner: any) => owner.createdBy);
    const updateName = selectedOwner 
      ? `${selectedOwner.nameFirst || ""} ${selectedOwner.nameLast || ""}`
      : propertyDetails?.ownerInfo?.ownerNames?.[0]?.fullName ?? "-";
      
    nameRef.current = updateName;
    setOwnerFullName(updateName);
  }, [propertyDetails, owner]);
  
  return (
    <div className={` ${styles.propertyDetails}`}>
          <BelowMap />
      <div className="wrapper">
    
        {Object.entries(sections).map(([key, value]) => (
          <ShadowBox title={key} key={key}>
            <div className={`dflex ${styles.row}`}>
              {value.map(({ title, value }) => (
                <SectionItem title={title} value={value} key={title} />
              ))}
            </div>
          </ShadowBox>
        ))}

{editNameModal && (
        <>
          <Dialog
            className="stepsDialog editModal"
            onClose={handleEditName}
            open={editNameModal}
          >
            <div className={`editModal`}>
              <label>Owner name*</label>
              <input
                placeholder="Owner name"
                type="text"
                defaultValue={ownerFullName}
                onChange={(e: any) => (nameRef.current = e.target.value.trim())}
                disabled={loading || resetLoading}
                maxLength={100}
              />

              <div className={` editModal__button dflex justifyCenter`}>
                <div className={` editModal__col `}>
                  <Button
                    className="primary full animated"
                    label="Save"
                    action={handleSaveName}
                    clicked={loading}
                    disabled={loading || resetLoading}
                  />
                </div>
                <div className={` editModal__col `}>
                  <Button
                    className="outline full "
                    label="Reset"
                    action={handleResetName}
                    clicked={resetLoading}
                    disabled={resetLoading || loading}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
      </div>
    </div>
  );
};
export default PropertyDetailTab;
