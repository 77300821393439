import { Tab, Tabs, Tooltip } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  ROLE_PERMISSION_STATUS,
  SUBSCRITPTION_STATUS,
} from "../../constants/constants";
import { PermissionListEnum } from "../../organisms/auth/enum";
import { Billings } from "../../organisms/billing/Billings";
import { getSubscriptions } from "../../organisms/billing/selectors";
import Integrations from "../../organisms/integrations/Integrations";
import { Details } from "../../organisms/settingdetails/Details";
import { Usage } from "../../organisms/usage/Usage";
import MyTeam from "../../pages/myTeam/MyTeam";
import { Security } from "../../pages/security/Security";
import { getProfileInfo } from "../profileMenu/selectors";
import { SettingsTabs } from "./enum";
import styles from "./SettingHeader.module.scss";

export const SettingHeader = () => {
  const [selectedTab, setSelectedTab] = useState<SettingsTabs>(
    SettingsTabs.DETAILS
  );
  const subscriptions = useSelector((state) => getSubscriptions(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  return (
    <div className={`${styles.setting}`}>
      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        className={`${styles.setting__header}`}
      >
        <Tab
          value={SettingsTabs.DETAILS}
          label="My Details"
          wrapped
          data-testid="MyDetails"
        />
        <Tab
          value={SettingsTabs.USAGE}
          label={
            <Tooltip
              title={
               
                profileInfo?.isSeatSubscriptionActive === false
                  ? SUBSCRITPTION_STATUS.DisableUser
                  : profileInfo?.userRoleName !==
                    PermissionListEnum.ORGANISATION_OWNER
                  ? ROLE_PERMISSION_STATUS.NoAccess
                  : ""
              }
            >
              <span>Usage</span>
            </Tooltip>
          }
          wrapped
          disabled={
            profileInfo?.userRoleName !==
              PermissionListEnum.ORGANISATION_OWNER ||
            profileInfo?.isSeatSubscriptionActive === false
          }
        />

        <Tab
          value={SettingsTabs.SECURITY}
          label={
            <Tooltip
              title={
                
                profileInfo?.isSeatSubscriptionActive === false
                  ? SUBSCRITPTION_STATUS.DisableUser
                  : ""
              }
            >
              <span>Security</span>
            </Tooltip>
          }
          wrapped
          disabled={
            profileInfo?.isSeatSubscriptionActive === false
          }
        />

        <Tab
          value={SettingsTabs.BILLINGS}
          label={
            <Tooltip
              title={
              
                profileInfo?.isSeatSubscriptionActive === false
                  ? SUBSCRITPTION_STATUS.DisableUser
                  :  profileInfo?.userRoleName !==
                  PermissionListEnum.ORGANISATION_OWNER
                  ? ROLE_PERMISSION_STATUS.NoAccess
                  : ""
              }
            >
              <span>Billing</span>
            </Tooltip>
          }
          wrapped
          data-testid="Billing"
          disabled={
              profileInfo?.isSeatSubscriptionActive === false || profileInfo?.userRoleName !==
              PermissionListEnum.ORGANISATION_OWNER
          }
        />
        <Tab
          value={SettingsTabs.INTEGRATIONS}
          label={
            <Tooltip
              title={
                subscriptions?.generalSubData &&
                !(
                  profileInfo?.userRoleName !==
                    PermissionListEnum.ORGANISATION_OWNER &&
                  profileInfo?.isSeatSubscriptionActive === false
                )
                  ? subscriptions?.generalSubData?.lastPaymentStatus ===
                    "card_declined"
                    ? SUBSCRITPTION_STATUS.CardDecline
                    : ""
                  : 
                    profileInfo?.isSeatSubscriptionActive === false
                  ? SUBSCRITPTION_STATUS.DisableUser
                  : profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER ? SUBSCRITPTION_STATUS.NoGeneralAccessNewUser : SUBSCRITPTION_STATUS.NoGeneralAccess
              }
            >
              <span>Integrations</span>
            </Tooltip>
          }
          wrapped
          data-testid="Integrations"
          disabled={
            !subscriptions?.generalSubData ||
            subscriptions?.generalSubData?.lastPaymentStatus ===
              "card_declined" ||
            (
              profileInfo?.isSeatSubscriptionActive === false)
          }
        />
       <Tab
          value={SettingsTabs.MYTEAM}
          label={
            <Tooltip
              title={ 
               
                profileInfo?.isSeatSubscriptionActive === false
                  ? SUBSCRITPTION_STATUS.DisableUser
                  :subscriptions?.generalSubData
                  ? subscriptions?.generalSubData?.lastPaymentStatus ===
                    "card_declined"
                    ? SUBSCRITPTION_STATUS.CardDecline
                    : ""
                  : profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER ? SUBSCRITPTION_STATUS.NoGeneralAccessNewUser : SUBSCRITPTION_STATUS.NoGeneralAccess
              }
            >
              <span>Manage Team</span>
            </Tooltip>
          }
          wrapped
          disabled={
            !subscriptions?.generalSubData  ||
            subscriptions?.generalSubData?.lastPaymentStatus ===
              "card_declined" ||
              (
                profileInfo?.isSeatSubscriptionActive === false)
                }
        />
      </Tabs>

      {selectedTab === SettingsTabs.DETAILS && <Details />}
      {selectedTab === SettingsTabs.USAGE && <Usage />}
      {selectedTab === SettingsTabs.SECURITY && <Security />}
      {selectedTab === SettingsTabs.BILLINGS && <Billings />}
      {selectedTab === SettingsTabs.INTEGRATIONS && <Integrations />}
      {selectedTab === SettingsTabs.MYTEAM && <MyTeam />}
    </div>
  );
};
