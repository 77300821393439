import { format } from "date-fns";
import { calculateDatesByRange, getParseFloatValue } from "../../utils/utils";
import {
  ADVANCED_FILTER_KEYS,
  FILTER_LIST_MAP,
  MARKET_STATUS_METADATA,
  QUICK_FILTERS_LIST,
  SELECTED_FILTER_UPDATE_LIST,
} from "./constants";
import { FilterValueType } from "./enum";
import { FilterMapValueType } from "./interface";
export function cleanFilter(obj: any) {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value && typeof value === "object") {
      if (Array.isArray(value)) {
        value.length === 0 && delete obj[key];
      } else {
        if (!value.min || !value.max) {
          delete obj[key];
        }
      }
    } else if (value == null) {
      delete obj[key];
    }
  });
}

export const createAdvancedFilters = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const filterArray = [];
  filterArray.push(
    getPropertyCharacteristicsFilter(
      data.propertyCharacteristicsFilter,
      setAppliedAdvancedFilters
    )
  );
  filterArray.push(
    getAuctionsPreforeclousreFilter(
      data.auctionsPreforeclousreFilter,
      setAppliedAdvancedFilters
    )
  );
  filterArray.push(
    getOwnershipFilter(data.ownershipFilter, setAppliedAdvancedFilters)
  );
  filterArray.push(
    getTaxLienFilter(data.taxLienFilter, setAppliedAdvancedFilters)
  );
  filterArray.push(
    getValuationEquityFilter(
      data.valuationEquityFilter,
      setAppliedAdvancedFilters
    )
  );
  filterArray.push(
    getMortgageInfoFilter(data.mortgageInfoFilter, setAppliedAdvancedFilters)
  );
  filterArray.push(
    getCashBuyersFilter(data.cashBuyersFilter, setAppliedAdvancedFilters)
  );
  filterArray.push(
    getDemographicsFilter(data.demographicsFilter, setAppliedAdvancedFilters)
  );
  return filterArray;
};

const getPropertyCharacteristicsFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  let classifications = createClassifications(data);
  let homeFeatures = createHomeFeatures(data);

  const filter = {
    squareFeet: {
      min: Number(data.minSquareFeet),
      max: Number(data.maxSquareFeet),
    },
    lotSize: {
      min: Number(data.minLotSize),
      max: Number(data.maxLotSize),
    },
    yearBuilt: {
      min: Number(data.yearBuiltMin),
      max: Number(data.yearBuiltMax),
    },
    storyCount: {
      min: Number(data.storiesMin),
      max: Number(data.storiesMax),
    },
    bedroomCount: {
      min: Number(data.minBedrooms),
      max: Number(data.maxBedrooms),
    },
    bathroomCount: {
      min: Number(data.minBathrooms),
      max: Number(data.maxBathrooms),
    },
    ...(data.classifications === "RESIDENTIAL"
      ? { propertyType: data.propertyType }
      : {}),

    ...classifications,
    ...homeFeatures,
  };
  cleanFilter(filter);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      propertyCharacteristicsFilter: getFilterAppliedCount(data),
    }));
  return filter;
};

const getAuctionsPreforeclousreFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  const filter = {
    ...createAuctionsPreforeclousreStatus(data),
    // ...data.auctionsPreforeclousreSearchRange,
    ...(data.auctionsPreforeclousreSearchRange?.recordingDate
      ? {
          recordingDate: calculateDatesByRange(
            data.auctionsPreforeclousreSearchRange?.recordingDate
          ),
        }
      : {}),
    // reo: data.reo,
    isExcludeForeclosure: data.exclude_foreclosure,
    activeAuction: data.auction,
    ...(data.auctionDate?.startDate && data.auctionDate?.endDate
      ? {
          auctionDate: {
            startDate: new Date(
              new Date(data.auctionDate?.startDate).getTime() - offset
            ).toISOString(),
            endDate: new Date(
              new Date(data.auctionDate?.endDate).getTime() - offset
            ).toISOString(),
          },
        }
      : {}),
  };

  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      auctionsPreforeclousreFilter: getFilterAppliedCount(data),
    }));
  return filter;
};

const getOwnershipFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  let ownerType = createOwnerType(data);
  let absenteeOwner = createAbsenteOwner(data);
  let occupancy = createOccupancy(data);
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  const filter = {
    ...ownerType,
    ...absenteeOwner,
    ...occupancy,
    // years_owned_min: data.yearsOwnedMin,
    // years_owned_max:data.yearsOwnedMax,
    yearsOwned: {
      min: Number(data.yearsOwnedMin),
      max: Number(data.yearsOwnedMax),
    },
    propertyPurchasePrice: {
      min: getParseFloatValue(data.lastSalePriceMin),
      max: getParseFloatValue(data.lastSalePriceMax),
    },
    // last_sale_price_min:getParseFloatValue(data.lastSalePriceMin),
    // last_sale_price_max: getParseFloatValue(data.lastSalePriceMax),
  };
  // Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  cleanFilter(filter);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      ownershipFilter: getFilterAppliedCount(data),
    }));

  return {
    ...filter,
    ...(data?.lastSaleDateRange?.startDate && data?.lastSaleDateRange?.endDate
      ? {
          propertyPurchaseDate: {
            startDate: new Date(
              new Date(data.lastSaleDateRange?.startDate).getTime() - offset
            ).toISOString(),
            endDate: new Date(
              new Date(data.lastSaleDateRange?.endDate).getTime() - offset
            ).toISOString(),
          },
        }
      : {}),
  };
};
const getTaxLienFilter = (data: any, setAppliedAdvancedFilters?: Function) => {
  const filter: any = {
    ...(data.taxLien ? { isTaxLien: data.taxLien } : {}),
    taxDelinquentYear: {
      min: getParseFloatValue(data.taxDelinquentYearMin),
      max: getParseFloatValue(data.taxDelinquentYearMax),
    },
    // tax_delinquent_year_min: data.taxDelinquentYearMin,
    // tax_delinquent_year_max: data.taxDelinquentYearMax,
  };
  // Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  cleanFilter(filter);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      taxLienFilter: getFilterAppliedCount(data),
    }));
  return filter;
};
const getValuationEquityFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const filter: any = {
    equityPercent: {
      min: getParseFloatValue(data.equityPercentMin),
      max: getParseFloatValue(data.equityPercentMax),
    },

    estimatedEquity: {
      min: getParseFloatValue(data.estimatedEquityMin),
      max: getParseFloatValue(data.estimatedEquityMax),
    },
    estimatedValue: {
      min: getParseFloatValue(data.estimatedValueMin),
      max: getParseFloatValue(data.estimatedValueMax),
    },
    assessedValue: {
      min: getParseFloatValue(data.assessedValueMin),
      max: getParseFloatValue(data.assessedValueMax),
    },
    assessedLandValue: {
      min: getParseFloatValue(data.assessedLandValueMin),
      max: getParseFloatValue(data.assessedLandValueMax),
    },
    assessedImprovemnetValue: {
      min: getParseFloatValue(data.assessedImprovementValueMin),
      max: getParseFloatValue(data.assessedImprovementValueMax),
    },
  };
  // Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  cleanFilter(filter);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      valuationEquityFilter: getFilterAppliedCount(data),
    }));
  return filter;
};
const getMortgageInfoFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const filter: any = {
    isWithoutMortgage: data.freeClear,
    // assumableDebt: data.assumableDebt,
    ...(Object.keys(data).includes("mortgageMin") &&
    Object.keys(data).includes("mortgageMax") &&
    (data.mortgageMin || data.mortgageMax)
      ? {
          mortgageBalance: {
            min: data.mortgageMin ? getParseFloatValue(data.mortgageMin) : 0,
            max: data.mortgageMax
              ? getParseFloatValue(data.mortgageMax)
              : 10000,
          },
        }
      : {}),
    loanType: data.loanType,
    isAdjustableMortgage: data.adjustable_rate,
  };
  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  filter?.loanType?.length === 0 && delete filter["loanType"];
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      mortgageInfoFilter: getFilterAppliedCount(data),
    }));
  return filter;
};

const getCashBuyersFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const filter: any = {
    ...(data.cashBuyer ? { cashPurchase: data.cashBuyer } : {}),
    propertiesOwned: {
      min: getParseFloatValue(data.propertiesOwnedMin),
      max: getParseFloatValue(data.propertiesOwnedMax),
    },
    portfolioValue: {
      min: getParseFloatValue(data.portfolioValueMin),
      max: getParseFloatValue(data.portfolioValueMax),
    },
    portfolioEquity: {
      min: getParseFloatValue(data.portfolioEquityMin),
      max: getParseFloatValue(data.portfolioEquityMax),
    },
    portfolioDebt: {
      min: getParseFloatValue(data.portfolioMortgageBalanceMin),
      max: getParseFloatValue(data.portfolioMortgageBalanceMax),
    },
  };
  // Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);

  cleanFilter(filter);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      cashBuyersFilter: getFilterAppliedCount(data),
    }));
  return filter;
};
const getDemographicsFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  const filter: any = {
    // death: data.death,
    ...(data.inherited ? { isInherited: data.inherited } : {}),

    // investor_buyer: data.investorBuyer,
    // private_lender: data.privateLender,
    householdIncome: {
      min: getParseFloatValue(data.medianIncomeMin),
      max: getParseFloatValue(data.medianIncomeMax),
    },
    ownerAge: {
      min: getParseFloatValue(data.ownerAgeMin),
      max: getParseFloatValue(data.ownerAgeMax),
    },
  };
  // Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  cleanFilter(filter);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      demographicsFilter: getFilterAppliedCount(data),
    }));
  return {
    ...filter,
    ...(data.divorceDate?.startDate && data.divorceDate?.endDate
      ? {
          divorceDate: {
            minDate: new Date(
              new Date(data.divorceDate?.startDate).getTime() - offset
            ).toISOString(),
            maxDate: new Date(
              new Date(data.divorceDate?.endDate).getTime() - offset
            ).toISOString(),
          },
        }
      : {}),
  };
};

const createClassifications = (data: any) => {
  let classifications: any = { propertyCategory: [] };
  if (data?.classifications && data.classifications.length > 0) {
    classifications.propertyCategory = [data.classifications];
  } else {
    delete classifications.propertyCategory;
  }
  return classifications;
};
const createHomeFeatures = (data: any) => {
  let homeFeatures: any = { propertyFeatures: [] };
  if (data?.homeFeatures && data.homeFeatures.length > 0) {
    homeFeatures.propertyFeatures = [...data.homeFeatures];
  } else {
    delete homeFeatures.propertyFeatures;
  }
  return homeFeatures;
};

const createOwnerType = (data: any) => {
  let filter: any = { ownerType: [] };
  // let individualSelected=false;
  // let corporateSelected=false;
  // data?.ownerType?.forEach((el: any) => {
  //   // if (OWNER_TYPE_OPTIONS_MAP[el]) {
  //   filter = { ...filter, ...OWNER_TYPE_OPTIONS_MAP[el] };
  //   //   if (el === "INDIVIDUAL") {
  //   //     individualSelected = true;
  //   //   } else if (el === "CORPORATE") {
  //   //     corporateSelected = true;
  //   //   }
  // });

  // if (individualSelected && corporateSelected) {
  //   delete filter.corporate_owned;
  // }
  if (data?.ownerType && data.ownerType.length > 0) {
    filter.ownerType = [...data.ownerType];
  } else {
    delete filter.ownerType;
  }
  return filter;
};

const createOccupancy = (data: any) => {
  let filter: any = { occupancyType: [] };
  // let inStateSelected=false;
  // let outStateSelected=false;
  // let ownerOccupiedSelected = false;
  // data?.absenteeOwner?.forEach((el: any) => {
  //   if (ABSENTEE_OWNER_OPTIONS_MAP[el]) {
  //     filter = { ...filter, ...ABSENTEE_OWNER_OPTIONS_MAP[el] };
  //     if (el === "INSTATE") {
  //       inStateSelected = true;
  //     } else if (el === "OUTOFSTATE") {
  //       outStateSelected = true;
  //     }
  //   }
  // });

  // data?.occupancy?.forEach((el: any) => {
  //   // if(OCCUPANCY_OPTIONS_MAP[el]){
  //   filter = { ...filter, ...OCCUPANCY_OPTIONS_MAP[el] };
  //   //     if(el==="OWNEROCCUPIED"){
  //   //       ownerOccupiedSelected=true;
  //   //     }
  //   //   }
  //   //   if((inStateSelected || outStateSelected)  && ownerOccupiedSelected ){
  //   //     delete filter.absentee_owner;
  //   //     delete filter.in_state_owner;
  //   //   }
  //   // })
  // });

  if (data?.occupancy && data.occupancy.length > 0) {
    filter.occupancyType = [...data.occupancy];
  } else {
    delete filter.occupancyType;
  }
  return filter;
};

const createAbsenteOwner = (data: any) => {
  let filter: any = { absenteeOwner: [] };
  // let inStateSelected=false;
  // let outStateSelected=false;
  // data?.absenteeOwner?.forEach(
  //   (el: any) => {
  //     // if (ABSENTEE_OWNER_OPTIONS_MAP[el]) {
  //     filter = { ...filter, ...ABSENTEE_OWNER_OPTIONS_MAP[el] };
  //     // if (el === "INSTATE") {
  //     //   inStateSelected = true;
  //     // } else if (el === "OUTOFSTATE") {
  //     //   outStateSelected = true;
  //     // }
  //   }
  //   // if (inStateSelected && outStateSelected) {
  //   //   delete filter.absentee_owner;
  //   //   delete filter.in_state_owner;
  //   // }
  // );
  if (data?.absenteeOwner && data.absenteeOwner.length > 0) {
    filter.absenteeOwner = [...data.absenteeOwner];
  } else {
    delete filter.absenteeOwner;
  }
  return filter;
};
const createAuctionsPreforeclousreStatus = (data: any) => {
  let filter: any = { foreclosureStatus: [] };
  // data?.auctionsPreforeclousreStatus?.forEach((el: any) => {
  //   // console.log("filter for notice type",AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP[el])
  //     if (AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP[el]) {
  //       filter.foreclosureStatus = [
  //         ...filter.foreclosureStatus,
  //         ...AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP[el],
  //       ];
  //     }
  // });
  if (Array.isArray(data?.auctionsPreforeclousreStatus)) {
    filter.foreclosureStatus = [...data.auctionsPreforeclousreStatus];
  }
  if (filter?.foreclosureStatus?.length === 0) {
    delete filter["foreclosureStatus"];
  }
  return filter;
};

const getFilterAppliedCount = (filter: any): number => {
  return Object.keys(filter).filter((key) => {
    if (Array.isArray(filter?.[key])) {
      return filter?.[key].length > 0;
    } else {
      return filter?.[key];
    }
  }).length;
};

export const updateFilterFlags = (
  data: any,
  setAppliedAdvancedFiltersFlags: Function
) => {
  let filterCounts: any = {};
  ADVANCED_FILTER_KEYS.forEach((key) => {
    const count = Object.keys(data[key]).filter((filter) => {
      if (Array.isArray(data[key][filter])) {
        return data[key][filter].length > 0;
      } else {
        if (filter === "lastSaleDateRange") {
          return data[key]?.[filter]?.endDate || data[key]?.[filter]?.startDate
            ? true
            : false;
        }
        return data[key][filter];
      }
    }).length;
    filterCounts[key] = count;
  });
  setAppliedAdvancedFiltersFlags(filterCounts);
};
export const removeZeroValues = (data: any) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === 0) {
      delete data[key];
    }
  });
  return data;
};

export const createSelectedFilterList = (formValue: any) => {
  let list: any = [];
  Object.keys(formValue).forEach((parentKey) => {
    if (SELECTED_FILTER_UPDATE_LIST.includes(parentKey)) {
      Object.keys(formValue[parentKey]).forEach((key) => {
        let objKey = "";
        let filter = null;
        const filterMetaData = FILTER_LIST_MAP[parentKey][key];
        if (filterMetaData) {
          switch (filterMetaData.type) {
            case FilterValueType.ARRAY: {
              if (formValue?.[parentKey]?.[key]?.length > 0) {
                filter = getArrayFilterListItem(
                  filterMetaData,
                  formValue?.[parentKey]?.[key]
                );
              }
              objKey = key;
              break;
            }
            case FilterValueType.MIN_MAX: {
              if (
                formValue?.[parentKey]?.[filterMetaData.minKey || ""] &&
                formValue[parentKey]?.[filterMetaData.maxKey || ""]
              ) {
                filter = getMinMaxFilterListItem(
                  filterMetaData,
                  formValue?.[parentKey]
                );
              }
              objKey = `${filterMetaData.minKey}_${filterMetaData.maxKey}`;
              break;
            }
            case FilterValueType.DATE_RANGE: {
              filter = getDateRangeFilterListItem(
                filterMetaData,
                formValue?.[parentKey]?.[key]
              );
              objKey = key;
              break;
            }
            case FilterValueType.BOOLEAN: {
              if (formValue?.[parentKey]?.[key]) {
                filter = {
                  label: `${filterMetaData.label}`,
                  defaultValue: filterMetaData.defaultValue,
                  keys: filterMetaData.resetKeys,
                  type: filterMetaData.type,
                };
              }
              objKey = key;
              break;
            }
            case FilterValueType.RECORDING_DATE: {
              if (formValue?.[parentKey]?.[key]) {
                filter = {
                  label: `${filterMetaData.label} ${
                    filterMetaData.optionMap[
                      formValue?.[parentKey]?.[key]?.recordingDate
                    ]
                  }`,
                  defaultValue: filterMetaData.defaultValue,
                  keys: filterMetaData.resetKeys,
                  type: filterMetaData.type,
                };
              }
              objKey = key;
              break;
            }
            case FilterValueType.STRING: {
              if (formValue?.[parentKey]?.[key]) {
                filter = {
                  label: `${filterMetaData.label} ${
                    filterMetaData?.optionMap?.[formValue?.[parentKey]?.[key]]
                  }`,
                  defaultValue: filterMetaData.defaultValue,
                  keys: filterMetaData.resetKeys,
                  type: filterMetaData.type,
                };
              }
              objKey = key;
              break;
            }
            case FilterValueType.OBJECT: {
              break;
            }
          }
          filter && (list[objKey] = filter);
        }
      });
    }
  });
  if (formValue?.["marketStatus"]?.length > 0) {
    list["marketStatus"] = getArrayFilterListItem(
      MARKET_STATUS_METADATA,
      formValue?.["marketStatus"]
    );
  }
  if (formValue?.["quickFilters"]?.length > 0) {
    const filterList = QUICK_FILTERS_LIST.reduce(
      (prev, curr) => [...prev, ...curr.filters],
      [] as { label: string; value: string }[]
    );
    const selectedValue = filterList.filter((el) =>
      formValue?.["quickFilters"]?.includes(el.value)
    );
    selectedValue?.forEach((el) => {
      list["quickFilters_" + el.value] = {
        label: el.label,
        defaultValue: "",
        keys: [],
        type: FilterValueType.QUICK_FILTER,
      };
    });
  }
  return list;
};

export const getMinMaxFilterListItem = (
  metaData: FilterMapValueType,
  parent: any
) => {
  const minValue = parent?.[metaData.minKey || ""] || null;
  const maxValue = parent?.[metaData.maxKey || ""] || null;
  if (minValue || maxValue) {
    return {
      label: `${metaData.label} ${minValue ? minValue : 0}-${
        maxValue ? maxValue : 10000
      }`,
      defaultValue: metaData.defaultValue,
      keys: metaData.resetKeys,
      type: metaData.type,
    };
  }
  return null;
};

export const getArrayFilterListItem = (
  metaData: FilterMapValueType,
  parent: any
) => {
  let filter = null;
  if (metaData?.optionMap) {
    filter = {
      label: `${metaData.label} ${parent
        ?.map((arrEl: any) => metaData.optionMap[arrEl])
        ?.join(", ")}`,
      defaultValue: metaData.defaultValue,
      keys: metaData.resetKeys,
      type: metaData.type,
    };
  } else {
    filter = {
      label: `${metaData.label} ${parent?.join(", ")}`,
      defaultValue: metaData.defaultValue,
      keys: metaData.resetKeys,
      type: metaData.type,
    };
  }
  return filter;
};

export const getDateRangeFilterListItem = (
  metaData: FilterMapValueType,
  parent: any
) => {
  const start = metaData.startDateKey;
  const end = metaData.endDateKey;
  let filter = null;
  if (parent && start && end && parent?.[start] && parent?.[end]) {
    filter = {
      label: `${metaData.label} ${format(
        parent?.[start],
        "MMM dd, yyyy"
      )} - ${format(parent?.[end], "MMM dd, yyyy")}`,
      defaultValue: metaData.defaultValue,
      keys: metaData.resetKeys,
      type: metaData.type,
    };
  }
  return filter;
};
